<header [ngClass]="headerClasses">
    <div class="header-top" #headerTop>
        <otb-error-flash-message></otb-error-flash-message>
        <div class="container">
            <div class="row">
                <div class="col-2 logo-container" *otbShowInEnv="['default']">
                    <img [src]="rootUrl + '/assets/img/default/logo.png'" alt="logo" />
                </div>
                <div class="col-4 logo-container d-none d-lg-block" *otbShowInEnv="['sh']">
                    <img [src]="rootUrl + '/assets/img/sh/logo.png'" alt="logo" />
                </div>
                <div class="col-4 logo-container d-block d-lg-none" *otbShowInEnv="['sh']">
                    <img [src]="rootUrl + '/assets/img/sh/logo.png'" alt="logo" />
                </div>
                <div class="col-2 offset-10" *ngIf="hasMultipleLanguages && !headless">
                    <div class="p-2 position-absolute lang-pick-container" #langPickContainer>
                        <otb-language-picker *otbShowInEnv="['mags-sop', 'justice']"></otb-language-picker>
                    </div>
                </div>
                <div class="col 12 d-flex justify-content-between align-items-center" *otbShowInEnv="['finance']">
                    <div>
                        <a
                            *ngIf="showEasyLanguagePage"
                            class="top-header-item"
                            [routerLink]="'/leichte_sprache'"
                            aria-label="Link zur Leichte Sprache"
                        >
                            <span class="contact">
                                <fa-icon class="fa" [icon]="faBookReader"></fa-icon>Leichte Sprache
                            </span>
                        </a>
                    </div>
                    <div>
                        <a
                            class="top-header-item"
                            href="https://www.finanzverwaltung.nrw.de/de/kontakt"
                            target="_blank"
                            rel="noopener"
                            aria-label="KONTAKT - Dieser Link wird in einer neuen Tab geöffnet"
                        >
                            <span class="contact"> <fa-icon class="fa" [icon]="faPaperPlane"></fa-icon>Kontakt </span>
                        </a>
                        <a
                            class="top-header-item"
                            [routerLink]="'/barrierefreiheit'"
                            aria-label="Link zur Erklärung der Barrierefreiheit"
                            >Barrierefreiheit</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="head" *otbShowInEnv="['justice', 'default', 'sh', 'education-check', 'finance', 'hmdj']">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-8 order-1">
                    <a
                        aria-label="Zurück zur Online-Terminbuchung"
                        (click)="clearStorage()"
                        [routerLink]="getRootUrl()"
                        class="headline-anchor"
                    >
                        <h1
                            class="headline"
                            [innerHTML]="'dynamische_frontend_texte.header.ueberschrift' | translate | safeStyle"
                        ></h1>
                        <p class="subheader" *otbShowInEnv="['education-check', 'finance', 'justice', 'hmdj']">
                            {{ 'dynamische_frontend_texte.header.sub_ueberschrift' | translate }}
                        </p>
                    </a>
                </div>
                <div
                    class="col-12 col-sm-4 order-0 order-sm-2 logo-container logo-mobile--sm"
                    *otbShowInEnv="['education-check']"
                >
                    <img
                        class="logo-image"
                        [src]="rootUrl + '/assets/img/education-check/bildungsscheck_logo.jpg'"
                        alt="Bildungsscheck Logo"
                    />
                </div>
                <div
                    class="col-12 col-sm-4 order-0 order-sm-2 logo-container logo-mobile--sm"
                    *otbShowInEnv="['finance']"
                >
                    <img
                        class="logo-image"
                        [src]="rootUrl + '/assets/img/finance/headline_logo.svg'"
                        alt="Finanzministerium Logo"
                    />
                </div>
            </div>
            <div class="row position-relative" *otbShowInEnv="['justice', 'default', 'hmdj']">
                <div class="tel-box">
                    <p [innerHTML]="'dynamische_frontend_texte.header.kontakt' | translate | safeStyle"></p>
                    <fa-icon
                        [icon]="phoneIcon"
                        [styles]="{
                            stroke: 'white',
                            color: 'white',
                            height: '12px',
                            'margin-right': '12px'
                        }"
                    >
                    </fa-icon>
                    <a
                        [attr.href]="'tel:' + ('dynamische_frontend_texte.header.tel-international' | translate)"
                        [attr.title]="'dynamische_frontend_texte.header.tel-visible' | translate"
                        [innerHTML]="'dynamische_frontend_texte.header.tel-visible' | translate | safeStyle"
                    >
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>
